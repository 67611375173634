import React, {useEffect} from "react";
import { Link } from "gatsby";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.css";
import { Breadcrumb } from "antd";
import Layout from "../components/layout";
// import FeaturePrducts from "../components/featureProducts";
import HeaderLight from "./../components/headerLight";
import Footer from "./../components/footer";
import { graphql } from "gatsby";
import PropTypes, { func, instanceOf } from "prop-types";
import Pagination from "react-js-pagination";
import productBanner from "../images/Product listing page_banner.png";
import { Spin, Alert, Tag } from "antd";
require("bootstrap/dist/css/bootstrap.css");
require("sweetalert/dist/sweetalert.css");

// import banner from "../images/11C52040-CB23-4C75-B655-A5070EC1AE54.png";
require("../css/product.css");
require("../css/productCat.css");
require("../css/responsive.scss");
//---------------

// ---------------
var ex = [];
let nodup1 = [],
  categoryTags = [],
  nodup2 = [],
  nodup3 = [];
var checkboxes1, checkboxes2, localCategory, localMenuCategory, localCategoryId;
var result = [];
var dataNP = [];
var mainCatFilter = [],
  subCatNamesArray = [],
  subSubCatNamesArray = [],
  subSubSubCatNamesArray = [],
  subSubSubCategoryNamesArray = [],
  cartData = [],
  catArray = [],
  catTagArray = [],
  onLoadMainCatArray = [];
var npCatId, comingsoon, npCatNameVar, npSubCatId, len;

var CheckboxId = "";

var entities = [
  ['amp', '&'],
  ['apos', '\''],
  ['#x27', '\''],
  ['#x2F', '/'],
  ['#39', '\''],
  ['#47', '/'],
  ['lt', '<'],
  ['gt', '>'],
  ['nbsp', ' '],
  ['quot', '"'],
  ['#038', '&']
];



class Products extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      color: "red",
      visible: false,
      screenWidth: "",
      headerClass: "headerSticky",
      // headerClass1: "headerhideonscroll1",
      // display: "block",
      displaySticky: "none",
      navClassName: "navbar-toggle",
      menuColor: "menuStickyColor",
      menuStickySignup: "",
      catFilter: [],
      subCatFilter: [],
      subSubSubCatFilter: [],
      productsLength: "",
      check: false,
      check1: false,
      check2: false,
      cartLength: 0,
      quantityValue: 1,
      tagDisp: "",
      activePage: 1,
      itemLength: 0,
      arrayProducts: [],
      // ddd: "block",
      activePage: 1,
      itemLength: 0,
      arrayBusinessProducts: [],
      addToCartToState: [],
      spin: false,
      categoryTags: [],
      ex: [],
      showDiv: false,
      activeMainCategory: null,
      categoriesFilter: []
    };
    this.saveCatdata = this.saveCatdata.bind(this);
    // this.saveSubCatData = this.saveSubCatData.bind(this);
    // console.log(window.localStorage.getItem("category"));
  }

  
  removeHtmlEntities(str) {
    for (var i = 0, max = entities.length; i < max; ++i) 
    str = str.replace(new RegExp('&'+entities[i][0]+';', 'g'), entities[i][1]);

    return str;
  }


  componentDidMount() {
    // console.log("Indside");
    // console.log("this.state.catFilter first", this.state.catFilter);

    comingsoon = false;

    this.reload();

    this.setState({
      headerClass: "headerSticky",
      displaySticky: "block",
      navClassName: "navbar-toggleSticky",
      menuColor: "menuStickyColorOnScroll",
      menuStickySignup: "menuStickySignup",
      npCatName: null,
      fixedSidebar: ""
    });
    this.saveCatdata(dataNP);
    this.displayCategories([68,69,70,71,102])
    this.checkPreFilters();
    window.addEventListener("scroll", this.listenScrollEvent);
  }

  listenScrollEvent = e => {
    if (window.scrollY > 400) {
      this.setState({ fixedSidebar: "fixedSidebar" });
    } else {
      this.setState({ fixedSidebar: "" });
    }
  };

  saveCatdata(catdata) {
    var onLoadCat = this.props.data.allWordpressWpProductCategories.edges;
    var data = this.props.data.allWordpressWpNativeproduct.edges;

    onLoadCat.forEach(function (element) {
      // alert(element.parent_element)
      if (element.node.parent_element == null) {
        onLoadMainCatArray.push(element);
      }
    });

    // console.log("onLoadMainCatArray", onLoadMainCatArray);

    onLoadMainCatArray.forEach(function (element1) {
      var npCatName = element1;
      onLoadCat.forEach(function (element) {
        if (element.node.parent_element != null) {
          if (element.node.parent_element.name == npCatName.node.name) {
            if (subCatNamesArray.includes(element) == false) {
              subCatNamesArray.push(element);
            }
          }
        }
      });
    });

    // console.log("subCatNamesArray", subCatNamesArray);
    subSubCatNamesArray.length = 0;
    subCatNamesArray.forEach(function (element1) {
      var npCatName = element1;
      onLoadCat.forEach(function (element) {
        if (
          element.node.parent_element != null &&
          element.node.parent_element.name != "Value Added Products"
        ) {
          if (element.node.parent_element.name == npCatName.node.name) {
            if (subSubCatNamesArray.includes(element) == false) {
              subSubCatNamesArray.push(element);
            }
          }
        }
      });
    });

    // console.log("subSubCatNamesArray", subSubCatNamesArray);

    subSubSubCatNamesArray.length = 0;
    subSubCatNamesArray.forEach(function (element1) {
      // console.log("element1", element1);
      var npCatName = element1;
      onLoadCat.forEach(function (element) {
        if (element.node.parent_element != null) {
          if (element.node.parent_element.name == npCatName.node.name) {
            if (subSubSubCatNamesArray.includes(element) == false) {
              subSubSubCatNamesArray.push(element);
            }
          }
        }
      });
    });
    // subSubSubCatNamesArray.unshift(subSubCatNamesArray[0]);
    // console.log("subSubSubCatNamesArray", subSubSubCatNamesArray);
    // console.log("subSubSubCatNamesArray", subSubSubCatNamesArray);
    this.setState({ subSubSubCatFilter: subSubCatNamesArray }, () => {
      // console.log();
    });
    // console.log("subSubCatNamesArray", subSubCatNamesArray);

    this.setState({ subCatFilter: subCatNamesArray }, () => {
      // console.log("subCatNamesArray", subCatNamesArray);

      // this.ifAllData();
    });

    // this.setState({ catFilter: catdata }, () => {
    //   // console.log("catFilter",this.state.catFilter)
    // });
    subCatNamesArray = [];
    subSubCatNamesArray = [];
  }

  checkPreFilters() {
    let wordpressIds = [68,69,70,71,102];
    let tempCategories = []
    if (this.props.data != null 
        && this.props.data.allWordpressWpProductCategoryV2 != null
        && this.props.data.allWordpressWpProductCategoryV2.edges != null
        && this.props.data.allWordpressWpProductCategoryV2.edges instanceof Array
        && this.props.data.allWordpressWpProductCategoryV2.edges.length != 0) {
      let allCatsV2 = this.props.data.allWordpressWpProductCategoryV2.edges;
      allCatsV2.forEach(edge => {
        if (wordpressIds.includes(edge.node.wordpress_parent)) {
          tempCategories.push({
            id : edge.node.wordpress_id,
            name: edge.node.name,
            checked: false
          })
        }
      })
    }

    let mainCatId = window.localStorage.getItem("mainCategoryId")
    let subCategoryId = window.localStorage.getItem("subCategoryId")
    console.log(mainCatId, subCategoryId, "Category Pre")
    if (mainCatId != null && mainCatId != "null") {
      this.displayCategories([parseInt(mainCatId)])
      this.setActiveCategory(parseInt(mainCatId))
    }
    let checkboxes = [...tempCategories]

    if (subCategoryId != null && subCategoryId != "null") {
      
      checkboxes.forEach((elem, index) => {
        if (elem.id == parseInt(subCategoryId)) {
          checkboxes[index].checked = true
        }
      })
      this.setState({
        categoriesFilter: checkboxes
      }, () => {
        this.displayProductsBasedOnCategories(
          parseInt(subCategoryId),
          "none",
          1
        )
      })
      
    } else {
      this.setState({
        categoriesFilter: checkboxes
      })
    }

    window.localStorage.setItem(
      "mainCategoryId",
      null
    );
    window.localStorage.setItem(
      "subCategoryId",
      null
    );
  }

  ifAllData() {
    var onLoadCat = this.props.data.allWordpressWpProductCategories.edges;
    var data = this.props.data.allWordpressWpNativeproduct.edges;
    if (window.localStorage.getItem("category") != "allData") {
      localCategory = window.localStorage.getItem("category");
      localMenuCategory = window.localStorage.getItem("menuCategory");
      if (document.getElementById(localCategory) != null) {
        document.getElementById(localCategory).checked = true;
      }
      if (localMenuCategory != "0" && document.getElementById(localMenuCategory) != null) {
        document.getElementById(localMenuCategory).checked = true;
      }

      this.setState({
        spin: true
      });
      setTimeout(() => {
        this.setState({
          spin: false
        });
      }, 1000);
      localCategoryId = window.localStorage.getItem("categoryId");

      if (localMenuCategory != 0 && localCategoryId != null) {
        var locCategoryId = localCategoryId.split(",");
        onLoadCat.map(ele => {
          locCategoryId.map(id => {
            if (ele.node.wordpress_id == parseInt(id)) {
              catTagArray.push(ele);
            }
          });
        });

        var catTagArrayNodup1 = catTagArray.filter(
          (elem, index, self) =>
            self.findIndex(obj => {
              return obj.node.id === elem.node.id;
            }) === index
        );
      } else {
        onLoadCat.map(ele => {
          if (ele.node.wordpress_id == parseInt(localCategoryId)) {
            catTagArray.push(ele);
          }
        });

        var catTagArrayNodup1 = catTagArray.filter(
          (elem, index, self) =>
            self.findIndex(obj => {
              return obj.node.id === elem.node.id;
            }) === index
        );
      }

      this.setState({
        categoryTags: catTagArrayNodup1
      });

      if (this.props.data.allWordpressWpNativeproduct != undefined ) {
        if (localCategoryId != null && localCategoryId.length != 1) {
          data.map(ee => {
            ee.node.product_categories.forEach(function (ele) {
              if (ele == localMenuCategory) {
                catArray.push(ee);
              }
            });
          });
        } else {
          data.map(ee => {
            ee.node.product_categories.forEach(function (ele) {
              if (ele == localCategory) {
                catArray.push(ee);
              }
            });
          });
        }
      }

      var nodup1 = catArray.filter(
        (elem, index, self) =>
          self.findIndex(obj => {
            return obj.node.id === elem.node.id;
          }) === index
      );

      this.setState(
        {
          addToCartToState: nodup1
        },
        () => {
          console.log("thi.state", this.state.addToCartToState);
        }
      );

      var len = nodup1.length;
      if (len != 0) {
        this.setState({ itemLength: len });

        function arrayTo2DArray2(list, howMany) {
          var idx = 0;
          var result = [];

          while (idx < list.length) {
            if (idx % howMany === 0) result.push([]);
            result[result.length - 1].push(list[idx++]);
          }

          return result;
        }
        var d = arrayTo2DArray2(nodup1, 12);
        var actPage;
        if (this.state.addToCartToState.length < 12) {
          actPage = 1;
        } else {
          actPage = this.state.activePage;
        }
        var dIndex = actPage - 1;
        this.setState({ catFilter: d[dIndex], arrayProducts: d }, () => { });
        comingsoon = false;
      } else {
        comingsoon = true;
      }
    }
    this.reload();
  }

  reload() {
    console.log("reloading")
    if (window.localStorage.getItem("cartDetails") == null) {
      window.localStorage.setItem("cartDetails", "[]");
    } else {
      var initialData = window.localStorage.getItem("cartDetails");
      var parse = JSON.parse(initialData);
      cartData = this.props.data.allWordpressWpNativeproduct.edges;
      if (parse.length == 0) {
        cartData.map((e, dataIndex) => {
          var cartItem = false;
          cartData[dataIndex].node.cartItem = cartItem;
        });
      } else {
        cartData = this.props.data.allWordpressWpNativeproduct.edges;
        var initialData = window.localStorage.getItem("cartDetails");
        var parse = JSON.parse(initialData);
        len = parse.length;
        this.setState({ cartLength: len });

        cartData.map((e, dataIndex) => {
          var cartItem = false;
          cartData[dataIndex].node.cartItem = cartItem;
          parse.map((product, index) => {
            if (product.id == e.node.id) {
              var cartItem = true;
              cartData[dataIndex].node.cartItem = cartItem;
            }
          });
        });
      }
      var len = cartData.length;
      this.setState({ itemLength: len });

      function arrayTo2DArray2(list, howMany) {
        var idx = 0;
        var result = [];

        while (idx < list.length) {
          if (idx % howMany === 0) result.push([]);
          result[result.length - 1].push(list[idx++]);
        }

        return result;
      }
      var d = arrayTo2DArray2(cartData, 12);
      var dIndex = this.state.activePage - 1;
      this.setState({ catFilter: d[dIndex], arrayProducts: d }, () => { });
    }
  }

  /*---------------------- Category -------------------------------------*/

  displayProductsBasedOnCategories(id, name, filterChangeType) {
    console.log(id, name)
    window.localStorage.setItem("category", "allData");
    window.localStorage.setItem("menuCategory", "");
    var _this = this;
    _this.setState({
      activePage: 1
    });
    var data = _this.props.data.allWordpressWpNativeproduct.edges;
    var onLoadCat = _this.props.data.allWordpressWpProductCategories.edges;
    // var checkboxes = document.querySelectorAll("input[type=checkbox]:checked");
    let categoriesFilters = [...this.state.categoriesFilter]
    var checkboxesFlatArray = [];

    categoriesFilters.forEach(elem => {
      if (elem.checked) {
        checkboxesFlatArray.push(parseInt(elem.id))
      }
    })

    // checkboxes.forEach(element => {
    //   if (element.className == "CategoryClass") {
    //     checkboxesFlatArray.push(parseInt(element.value))
    //   }
    // });


    categoryTags.length = 0;
    ex.length = 0;

    checkboxesFlatArray.forEach(catId => {
      let catFilter = categoriesFilters.find((val) => val.id == catId);
      if (catFilter != undefined) {
        categoryTags.push(catFilter)
      }
    })
    

    this.setState({
      categoryTags: categoryTags
    })

    if (filterChangeType == 1 && checkboxesFlatArray.length == 0) {
      if (this.state.activeMainCategory == null) {
        this.reload()
      } else {
        filterChangeType = 0
        id = this.state.activeMainCategory
      }
    }

    if (this.state.activeMainCategory == null && checkboxesFlatArray.length == 0 && filterChangeType == 1) {
      comingsoon = false
      this.reload()
    } else {
      subCatNamesArray.length = 0;
      var newCatFilter = [];
      if (filterChangeType == 0) {
        comingsoon = false;
        
        // _this.setState(
        //   {
        //     subCatFilter: subCatNamesArray,
        //     spin: true,
        //     addToCartToState: []
        //   },
        //   () => {
        //     setTimeout(() => {
        //       _this.setState({ spin: false });
        //     }, 600);
        //   }
        // );
        newCatFilter.length = 0;
        data.map(ee => {
          if (ee.node.product_category_v2.includes(id)) {
          newCatFilter.push(ee);
          }
        });

        var len = newCatFilter.length;

        if (len != 0) {
          _this.setState({ itemLength: len });

          function arrayTo2DArray2(list, howMany) {
            var idx = 0;
            var result = [];

            while (idx < list.length) {
              if (idx % howMany === 0) result.push([]);
              result[result.length - 1].push(list[idx++]);
            }

            return result;
          }
          var d = arrayTo2DArray2(newCatFilter, 12);
          var dIndex = _this.state.activePage - 1;
          _this.setState({ catFilter: d[dIndex], arrayProducts: d }, () => { });
        } else {
          comingsoon = true
        }
      } else {
        comingsoon = false;
        
        // _this.setState(
        //   {
        //     subCatFilter: subCatNamesArray,
        //     spin: true,
        //     addToCartToState: []
        //   },
        //   () => {
        //     setTimeout(() => {
        //       _this.setState({ spin: false });
        //     }, 600);
        //   }
        // );
        newCatFilter.length = 0;
        data.map(ee => {
          // console.log(ee, ee.node.product_category_v2, ee.node.product_category_v2.some(prod_cat_ids => checkboxesFlatArray.includes(prod_cat_ids)))
          if (ee.node.product_category_v2.some(prod_cat_ids => checkboxesFlatArray.includes(prod_cat_ids))) {
            newCatFilter.push(ee);
          }
        });

        var len = newCatFilter.length;

        if (len != 0) {
          _this.setState({ itemLength: len });

          function arrayTo2DArray2(list, howMany) {
            var idx = 0;
            var result = [];

            while (idx < list.length) {
              if (idx % howMany === 0) result.push([]);
              result[result.length - 1].push(list[idx++]);
            }

            return result;
          }
          var d = arrayTo2DArray2(newCatFilter, 12);
          var dIndex = _this.state.activePage - 1;
          _this.setState({ catFilter: d[dIndex], arrayProducts: d }, () => { });
        } else {
          comingsoon = true
        }
      }

    }
  }

  displayCategories(wordpressIds) {
    console.log(wordpressIds)
    console.log(this.props.data.allWordpressWpProductCategoryV2)
    let tempCategories = []
    if (this.props.data != null 
        && this.props.data.allWordpressWpProductCategoryV2 != null
        && this.props.data.allWordpressWpProductCategoryV2.edges != null
        && this.props.data.allWordpressWpProductCategoryV2.edges instanceof Array
        && this.props.data.allWordpressWpProductCategoryV2.edges.length != 0) {
      let allCatsV2 = this.props.data.allWordpressWpProductCategoryV2.edges;
      allCatsV2.forEach(edge => {
        if (wordpressIds.includes(edge.node.wordpress_parent)) {
          tempCategories.push({
            id : edge.node.wordpress_id,
            name: edge.node.name,
            checked: false
          })
        }
      })
    }

    this.setState({
      categoriesFilter: tempCategories
    })
  }

  /* ----------------------------------------BRANDS ---------------------------------------------*/

 

  /* ---------------ADD TO CART --------------------*/
  addToCart(addedId, _this) {
    if (window.localStorage.getItem("cartDetails") == null) {
      window.localStorage.setItem("cartDetails", "[]");
    } else {
      cartData = this.props.data.allWordpressWpNativeproduct.edges;
      var initialData = window.localStorage.getItem("cartDetails");
      var parse = JSON.parse(initialData);

      if (parse.length != 0) {
        var arrObj = {};
        arrObj["id"] = addedId;
        arrObj["quantity"] = 1;
        parse.push(arrObj);
        // console.log("parse", parse);

        var nodup = parse.filter(
          (elem, index, self) =>
            self.findIndex(obj => {
              return obj.id === elem.id;
            }) === index
        );

        nodup.map((e, index) => {
          if (e.id == arrObj["id"]) {
            nodup[index].quantity = arrObj["quantity"];
          }
        });

        cartData.map((e, dataIndex) => {
          if (addedId == e.node.id) {
            var cartItem = true;
            cartData[dataIndex].node.cartItem = cartItem;
          }
        });

        if (this.state.addToCartToState.length != 0) {
          var len = this.state.addToCartToState.length;
          this.setState({ itemLength: len });

          function arrayTo2DArray2(list, howMany) {
            var idx = 0;
            var result = [];

            while (idx < list.length) {
              if (idx % howMany === 0) result.push([]);
              result[result.length - 1].push(list[idx++]);
            }

            return result;
          }
          // console.log("arrayTo2DArray2", arrayTo2DArray2(data, 10));
          var d = arrayTo2DArray2(this.state.addToCartToState, 12);
          var actPage;
          if (this.state.addToCartToState.length < 12) {
            actPage = 1;
          } else {
            actPage = this.state.activePage;
          }
          var dIndex = actPage - 1;
          this.setState({ catFilter: d[dIndex], arrayProducts: d }, () => {
            // console.log("this.state.catFilter", this.state.catFilter);
          });
        } else {
          var len = cartData.length;
          this.setState({ itemLength: len });

          function arrayTo2DArray2(list, howMany) {
            var idx = 0;
            var result = [];

            while (idx < list.length) {
              if (idx % howMany === 0) result.push([]);
              result[result.length - 1].push(list[idx++]);
            }

            return result;
          }
          // console.log("arrayTo2DArray2", arrayTo2DArray2(data, 10));
          var d = arrayTo2DArray2(cartData, 12);
          // console.log("this.state.activePage", this.state.activePage);
          var dIndex = this.state.activePage - 1;
          this.setState({ catFilter: d[dIndex], arrayProducts: d }, () => {
            // console.log("this.state.catFilter", this.state.catFilter);
          });
        }

        // this.setState({ catFilter: cartData });

        window.localStorage.setItem("cartDetails", JSON.stringify(nodup));
        var len = nodup.length;
        this.setState({ cartLength: len });
      } else {
        cartData = this.props.data.allWordpressWpNativeproduct.edges;
        var arr = [];
        var arrObj = {};
        arrObj["id"] = addedId;
        arrObj["quantity"] = 1;
        arr.push(arrObj);
        window.localStorage.setItem("cartDetails", JSON.stringify(arr));
        var len = arr.length;
        this.setState({ cartLength: len });
        cartData.map((e, dataIndex) => {
          if (addedId == e.node.id) {
            var cartItem = true;
            cartData[dataIndex].node.cartItem = cartItem;
          }
        });

        if (this.state.addToCartToState.length != 0) {
          var len = this.state.addToCartToState.length;
          this.setState({ itemLength: len });

          function arrayTo2DArray2(list, howMany) {
            var idx = 0;
            var result = [];

            while (idx < list.length) {
              if (idx % howMany === 0) result.push([]);
              result[result.length - 1].push(list[idx++]);
            }

            return result;
          }
          // console.log("arrayTo2DArray2", arrayTo2DArray2(data, 10));
          var d = arrayTo2DArray2(this.state.addToCartToState, 12);
          var actPage;
          if (this.state.addToCartToState.length <= 12) {
            actPage = 1;
          } else {
            actPage = this.state.activePage;
          }
          var dIndex = actPage - 1;
          this.setState({ catFilter: d[dIndex], arrayProducts: d }, () => {
            // console.log("this.state.catFilter", this.state.catFilter);
          });
        } else {
          var len = cartData.length;
          this.setState({ itemLength: len });

          function arrayTo2DArray2(list, howMany) {
            var idx = 0;
            var result = [];

            while (idx < list.length) {
              if (idx % howMany === 0) result.push([]);
              result[result.length - 1].push(list[idx++]);
            }

            return result;
          }
          // console.log("arrayTo2DArray2", arrayTo2DArray2(data, 10));
          var d = arrayTo2DArray2(cartData, 12);
          // console.log("this.state.activePage", this.state.activePage);
          var dIndex = this.state.activePage - 1;
          this.setState({ catFilter: d[dIndex], arrayProducts: d }, () => {
            // console.log("this.state.catFilter", this.state.catFilter);
          });
        }

        // this.setState({ catFilter: cartData });
      }
    }
  }

  removeFromCart(id, _this) {
    // console.log("removeFromCart", id, _this);
    if (window.localStorage.getItem("cartDetails") == null) {
      window.localStorage.setItem("cartDetails", "[]");
    } else {
      cartData = this.props.data.allWordpressWpNativeproduct.edges;
      var initialData = window.localStorage.getItem("cartDetails");
      var parse = JSON.parse(initialData);

      parse = parse.filter(function (obj) {
        return obj.id !== id;
      });
      // console.log("parse", parse);
      len = parse.length;
      this.setState({ cartLength: len });
      window.localStorage.setItem("cartDetails", JSON.stringify(parse));

      cartData.map((e, dataIndex) => {
        if (id == e.node.id) {
          var cartItem = false;
          cartData[dataIndex].node.cartItem = cartItem;
        }
      });

      if (this.state.addToCartToState.length != 0) {
        var len = this.state.addToCartToState.length;
        this.setState({ itemLength: len });

        function arrayTo2DArray2(list, howMany) {
          var idx = 0;
          var result = [];

          while (idx < list.length) {
            if (idx % howMany === 0) result.push([]);
            result[result.length - 1].push(list[idx++]);
          }

          return result;
        }
        // console.log("arrayTo2DArray2", arrayTo2DArray2(data, 10));
        var d = arrayTo2DArray2(this.state.addToCartToState, 12);
        var actPage;
        if (this.state.addToCartToState.length < 12) {
          actPage = 1;
        } else {
          actPage = this.state.activePage;
        }
        var dIndex = actPage - 1;
        this.setState({ catFilter: d[dIndex], arrayProducts: d }, () => {
          // console.log("this.state.catFilter", this.state.catFilter);
        });
      } else {
        var len = cartData.length;
        this.setState({ itemLength: len });

        function arrayTo2DArray2(list, howMany) {
          var idx = 0;
          var result = [];

          while (idx < list.length) {
            if (idx % howMany === 0) result.push([]);
            result[result.length - 1].push(list[idx++]);
          }

          return result;
        }
        // console.log("arrayTo2DArray2", arrayTo2DArray2(data, 10));
        var d = arrayTo2DArray2(cartData, 12);
        // console.log("this.state.activePage", this.state.activePage);
        var dIndex = this.state.activePage - 1;
        this.setState({ catFilter: d[dIndex], arrayProducts: d }, () => {
          // console.log("this.state.catFilter", this.state.catFilter);
        });
      }
      // this.setState({ catFilter: cartData });
    }
  }

  handlePageChange(pageNumber) {
    var _this = this;
    _this.setState({
      activePage: pageNumber,
      catFilter: this.state.arrayProducts[pageNumber - 1]
    });
  }

  pClick() {
    window.localStorage.setItem("category", "allData");
    window.localStorage.setItem("menuCategory", "");
    var checkboxes = [...this.state.categoriesFilter]
    var onLoadCat = this.props.data.allWordpressWpProductCategories.edges;
    var data = this.props.data.allWordpressWpNativeproduct.edges;
    var newCatFilter = [];
    checkboxes.forEach(function (element) {
      element.checked = false;
    });
    this.setState({
      categoryTags: [],
      addToCartToState: [],
      categoriesFilter: checkboxes,
      activeMainCategory: null
    });

    comingsoon = false;
    subCatNamesArray.length = 0;
    onLoadMainCatArray.forEach(function (element1) {
      var npCatName = element1;
      onLoadCat.forEach(function (element) {
        if (element.node.parent_element != null) {
          if (element.node.parent_element.name == npCatName.node.name) {
            if (subCatNamesArray.includes(element) == false) {
              subCatNamesArray.push(element);
            }
          }
        }
      });
    });
    this.setState(
      {
        subCatFilter: subCatNamesArray,
        spin: true
        // ddd: "block"
      },
      () => {
        setTimeout(() => {
          this.setState({ spin: false });
        }, 600);
      }
    );

    newCatFilter.length = 0;
    data.map(ee => {
      newCatFilter.push(ee);
    });

    var len = newCatFilter.length;

    if (len != 0) {
      this.setState({ itemLength: len });

      function arrayTo2DArray2(list, howMany) {
        var idx = 0;
        var result = [];

        while (idx < list.length) {
          if (idx % howMany === 0) result.push([]);
          result[result.length - 1].push(list[idx++]);
        }

        return result;
      }
      var d = arrayTo2DArray2(newCatFilter, 12);
      var dIndex = this.state.activePage - 1;
      this.setState({ catFilter: d[dIndex], arrayProducts: d }, () => { });
    }
    subSubCatNamesArray.length = 0;
    subCatNamesArray.forEach(function (element1) {
      var npCatName = element1;
      onLoadCat.forEach(function (element) {
        if (
          element.node.parent_element != null &&
          element.node.parent_element.name != "Value Added Products"
        ) {
          if (element.node.parent_element.name == npCatName.node.name) {
            if (subSubCatNamesArray.includes(element) == false) {
              subSubCatNamesArray.push(element);
            }
          }
        }
      });
    });


    // subSubSubCatNamesArray.unshift(subSubCatNamesArray[0]);
    this.setState({
      subSubSubCatFilter: subSubCatNamesArray
    });

    this.displayCategories([68,69,70,71,102])
  }

  collapseIcon() {
    this.setState({ showDiv: false });
  }

  expandIcon() {
    this.setState({ showDiv: true });
  }

  setActiveCategory(catId) {
    var checkboxes = [...this.state.categoriesFilter]
    checkboxes.forEach(element => {
      element.checked = false;
    })
    this.setState({
      categoriesFilter: checkboxes
    })
    if (catId == this.state.activeMainCategory) {
      this.setState({
        activeMainCategory: null
      })
      this.displayCategories([68,69,70,71,102])
      this.reload()
    } else {
      this.setState({
        activeMainCategory: catId
      })
      this.displayCategories([catId])
      this.displayProductsBasedOnCategories(catId,"hi",0)
    }
  }


  render() {
    var data = this.props.data;
    // data.allWordpressWpProductCategories.edges.forEach(element => {

    //   console.log("Node" ,element.node.name)
    // })
    const { itemLength, categoryTags } = this.state;

    // console.log(this.props.data)

    return (
      <div>
        <HeaderLight data={this.state.cartLength} />

        <Spin
          tip="Loading..."
          spinning={this.state.spin}
          wrapperClassName="spinClass"
        >
          <div>
            {/* <div className="row mrgTopPadding productBanner">
              <p className="productTitl">
                {" "}
                Handcrafted | Thoughtful | Ecofriendly{" "}
              </p>
              <p className="productText">
                {" "}
                A lot of thought goes into our products. The best of raw
                materials are sourced from certified farmers, handled with
                hygiene and care. Each product is handmade, with human touch
                echoing our belief in being grounded and being interconnected
                with the environment around us.{" "}
              </p>
              <p className="productText">
                A product, for us, is not a mere collection of raw materials
                tossed together. Each ingredient has a purpose as well as
                responsibility. The purpose is to heal, to nourish and to care
                and the responsibility is to dissolve into earth once disposed
                without contaminating her existance.
              </p>{" "}
              <p className="productText">
                {" "}
                At the end the product is for you to use, for you to cherish
                because you deserve the purity of a product the same way you
                deserve clean air, water and mindsets.
              </p>
              <div id="topOnClick" />
            </div> */}

            <div
              id="productCatMainDiv"
              className="row col-md-12 productCatMainDiv"
            >
              {this.state.showDiv ? (
                <div
                  id="mobileFilterId"
                  className="col-xs-12 col-md-12 col-sm-12"
                >
                  <a
                    className="NC-collapse-icon"
                    onClick={() => this.collapseIcon()}
                  >
                    <div className="col-xs-12 col-md-12 col-sm-12 Filterborder">
                      <p className="pFilter">Filter</p>
                      <img
                        className="NC-collapse-img-rightProductPage"
                        src="https://res.cloudinary.com/djoztpm50/image/upload/v1551860745/Native%20Circle/group-44.svg"
                      />
                    </div>
                  </a>
                  <div className="prod-filterCategory"
                  // style={{ textAlign: "center" }}
                  >
                    {/* <div style={{ display: "inline-block" }}> */}
                    <div className="BrandsMainDiv">
                      <p className="catText">Category</p>

                      {this.state.categoriesFilter.map((filter, filterIndex) =>{
                           return <ul className="ncCatList" key={filter.id}>
                              <li>
                              <input
                                type="checkbox"
                                value={filter.id}
                                className="CategoryClass"
                                id={filter.id}
                                checked={filter.checked}
                                onChange={() => {
                                  let tempSubCategories = [...this.state.categoriesFilter]
                                  tempSubCategories[filterIndex].checked = 
                                    !tempSubCategories[filterIndex].checked
                                  this.setState(
                                    {
                                      categoriesFilter: tempSubCategories
                                    },
                                    () => {
                                      this.displayProductsBasedOnCategories(
                                        filter.id,
                                        filter.name,
                                        1
                                      )
                                    }
                                  )
                                  }
                              }
                              // defaultChecked={this.state.check}
                              />
                              <label
                                htmlFor={filter.id}
                                className="ncCatListLabel"
                              >
                                {this.removeHtmlEntities(filter.name)}
                              </label>
                              </li>
                            </ul>
                      })}
                    </div>
                    {/* </div> */}
                    {/* <div className="prod-filterCategory"
                  // style={{ textAlign: "center" }}
                  > */}
                  {/*  Brand filter commented */}
                  {/* 
                    <div className="BrandsMainDiv">
                      {this.state.subCatFilter.length > 0 ? (
                        <p className="catText">Brands</p>
                      ) : (
                          ""
                        )}
                      {this.state.subCatFilter != undefined &&
                        this.state.subCatFilter.map(({ node, index }) => (
                          <ul className="ncCatList" key={index}>
                            <li>
                              <input
                                type="checkbox"
                                name="BrandsClass"
                                id={node.wordpress_id}
                                className="BrandsClass"
                                value={node.wordpress_id}
                                onChange={this.displayCatSubProducts.bind(
                                  this,
                                  node.wordpress_id,
                                  node.name
                                )}
                              // defaultChecked={this.state.check1}
                              />
                              <label
                                htmlFor={node.wordpress_id}
                                className="ncCatListLabel"
                              >
                                {node.name}
                              </label>
                            </li>
                          </ul>
                        ))}
                    </div> */}
                    {/* </div> */}
                    {/* <div className="prod-filterCategory"
                  // style={{ textAlign: "center" }}
                  > */}
                    {/* Product filter commented */}
                    {/* <div className="BrandsMainDiv">
                      {this.state.subSubSubCatFilter.length > 0 ? (
                        <p className="catText">Products</p>
                      ) : (
                          ""
                        )}
                      {this.state.subSubSubCatFilter != undefined &&
                        this.state.subSubSubCatFilter.map(
                          ({ node, index }) => (
                            <ul className={"ncCatList"} key={index}>
                              <li
                                onClick={this.displayCatSubSubProducts.bind(
                                  this,
                                  node.wordpress_id,
                                  node.name
                                )}
                              >
                                <input
                                  type="checkbox"
                                  name="ProductsClass"
                                  className="ProductsClass"
                                  id={node.wordpress_id}
                                  value={node.wordpress_id}
                                // defaultChecked={this.state.check2}
                                />
                                <label
                                  htmlFor={node.wordpress_id}
                                  className="ncCatListLabel"
                                >
                                  {node.name}
                                </label>
                              </li>
                            </ul>
                          )
                        )}
                    </div> */}
                    {/* </div> */}
                  </div>
                </div>
              ) : (
                  <div
                    id="mobileFilterId"
                    className="col-xs-12 col-md-12 col-sm-12"
                  >
                    <a
                      className="NC-collapse-icon"
                      onClick={() => this.expandIcon()}
                    >
                      <div className="col-xs-12 col-md-12 col-sm-12 Filterborder">
                        <p className="pFilter">Filter</p>
                        <img
                          className="NC-collapse-img-rightProductPage"
                          src="https://res.cloudinary.com/djoztpm50/image/upload/v1552297841/Native%20Circle/group-129.svg"
                        />
                      </div>
                    </a>
                  </div>
                )}

              {/* Category Sidebar */}
              <div
                id="desktopFilterId"
                className="col-md-2 productCatList pr-0"
              >
                {/*  <div className={!comingsoon ? this.state.fixedSidebar : ""}> */}
                <div>
                  <div style={{ display: "block" }}>
                    <p className="catText">Category</p>
                    {/* Previous logic */}
                    {/* {this.props.data.allWordpressWpProductCategories.edges.map(
                      ({ node, i }) =>
                        node.parent_element == null ? (
                          <ul className="ncCatList" key={i}>
                            <li>
                              <input
                                type="checkbox"
                                value={node.wordpress_id}
                                className="CategoryClass"
                                id={node.wordpress_id}
                                onChange={this.displayCatProducts.bind(
                                  this,
                                  node.wordpress_id,
                                  node.name
                                )}
                              // defaultChecked={this.state.check}
                              />
                              <label
                                htmlFor={node.wordpress_id}
                                className="ncCatListLabel"
                              >
                                {node.name}
                              </label>
                            </li>
                          </ul>
                     ) : (
                      ""
                    )
              )} */}
                  {
                    this.state.categoriesFilter.map((filter, filterIndex) =>{
                      return <ul className="ncCatList" key={filter.id}>
                            <li>
                              <input
                                type="checkbox"
                                value={filter.id}
                                className="CategoryClass"
                                id={filter.id}
                                checked={filter.checked}
                                onChange={() => {
                                  let tempSubCategories = [...this.state.categoriesFilter]
                                  tempSubCategories[filterIndex].checked = 
                                    !tempSubCategories[filterIndex].checked
                                  this.setState(
                                    {
                                      categoriesFilter: tempSubCategories
                                    },
                                    () => {
                                      this.displayProductsBasedOnCategories(
                                        filter.id,
                                        filter.name,
                                        1
                                      )
                                    }
                                  )
                                  }
                              }
                              // defaultChecked={this.state.check}
                              />
                              <label
                                htmlFor={filter.id}
                                className="ncCatListLabel"
                              >
                                {this.removeHtmlEntities(filter.name)}
                              </label>
                            </li>
                          </ul>
                    })
                  }
                  </div>
                    {/* Brand filter commented */}
                  {/* <div className="BrandsMainDiv">
                    {this.state.subCatFilter.length > 0 ? (
                      <p className="catText">Brands</p>
                    ) : (
                        ""
                      )}
                    {this.state.subCatFilter != undefined &&
                      this.state.subCatFilter.map(({ node, index }) => (
                        <ul className="ncCatList" key={index}>
                          <li>
                            <input
                              type="checkbox"
                              name="BrandsClass"
                              id={node.wordpress_id}
                              className="BrandsClass"
                              value={node.wordpress_id}
                              onChange={this.displayCatSubProducts.bind(
                                this,
                                node.wordpress_id,
                                node.name
                              )}
                            // defaultChecked={this.state.check1}
                            />
                            <label
                              htmlFor={node.wordpress_id}
                              className="ncCatListLabel"
                            >
                              {node.name}
                            </label>
                          </li>
                        </ul>
                      ))}
                  </div> */}
                  {/* Product filter commented */}
                  {/* <div className="BrandsMainDiv">
                    {this.state.subSubSubCatFilter.length > 0 ? (
                      <p className="catText">Products</p>
                    ) : (
                        ""
                      )}
                    {this.state.subSubSubCatFilter != undefined &&
                      this.state.subSubSubCatFilter.map(({ node, index }) => (
                        <ul className={"ncCatList"} key={index}>
                          <li
                            onClick={this.displayCatSubSubProducts.bind(
                              this,
                              node.wordpress_id,
                              node.name
                            )}
                          >
                            <input
                              type="checkbox"
                              name="ProductsClass"
                              className="ProductsClass"
                              id={node.wordpress_id}
                              value={node.wordpress_id}
                            // defaultChecked={this.state.check2}
                            />
                            <label
                              htmlFor={node.wordpress_id}
                              className="ncCatListLabel"
                            >
                              {node.name}
                            </label>
                          </li>
                        </ul>
                      ))}
                  </div> */}
                </div>
              </div>

              <div className="col-md-10 productRightMainDiv">
                <div className="container">
                  <div className="row mBottom">
                    <div className={`col-sm-12 col-md-4 col-lg-2  col-xl-2 categoryFilters 
                      ${this.state.activeMainCategory==68 ? "active" : "" }` }
                      onClick={this.setActiveCategory.bind(this, 68)}>
                      <p className="categoryName">Edible organics</p>
                    </div>
                    <div className={`col-sm-12 col-md-4 col-lg-2  col-xl-2 categoryFilters 
                      ${this.state.activeMainCategory==69 ? "active" : "" }` }
                      onClick={this.setActiveCategory.bind(this, 69)}>
                      <p className="categoryName">Herbal infusions</p>
                    </div>
                    <div className={`col-sm-12 col-md-4 col-lg-2  col-xl-2 categoryFilters 
                      ${this.state.activeMainCategory==70 ? "active" : "" }` }
                      onClick={this.setActiveCategory.bind(this, 70)}>
                      <p className="categoryName">Personal Care</p>
                    </div>
                    <div className={`col-sm-12 col-md-4 col-lg-2  col-xl-2 categoryFilters 
                      ${this.state.activeMainCategory==71 ? "active" : "" }` }
                      onClick={this.setActiveCategory.bind(this, 71)}>
                      <p className="categoryName">Domestic Care</p>
                    </div>
                    <div className={`col-sm-12 col-md-4 col-lg-2  col-xl-2 categoryFilters 
                      ${this.state.activeMainCategory==102 ? "active" : "" }` }
                      onClick={this.setActiveCategory.bind(this, 102)}>
                      <p className="categoryName">Combos</p>
                    </div>
                  </div>
                </div>

                {this.state.categoryTags != null 
                  && this.state.categoryTags.length != 0 
                 && (
                  <div className="container">
                  <div className="row mBottom">
                    <div className="col-md-10 col-sm-10 col-xs-10">
                      <div className="catTagsDiv">
                        <div className="row">
                          <div style={{display: "flex", alignItems: "center"}} className="col-md-2 col-sm-2 col-xs-2">
                            <p className="pcat">Applied Filters :</p>
                          </div>
                          <div 
                            style={{
                              display: "flex",
                              overflowX: "scroll",
                              padding: "2%"
                            }} 
                            className="col-md-10 col-sm-10 col-xs-10 px-0">
                            {this.state.categoryTags.map(tag => {
                              console.log(tag)
                              return (
                                // <p className="categoryTags">{e}</p>
                                <Tag 
                                  key={tag.id}
                                  style={{
                                    display: "flex",
                                    width: "fit-content",
                                    alignItems: "center",
                                    height: "fit-content",
                                    padding: "3px 10px 3px 10px"
                                  }}
                                  closable 
                                  onClose={(e) => {
                                    let tempCategoryFilters = [...this.state.categoriesFilter]
                                    tempCategoryFilters.forEach((elem, index) => {
                                      if (elem.id = tag.id) {
                                        tempCategoryFilters[index].checked = false
                                      }
                                    })
                                    this.setState({
                                      categoriesFilter : tempCategoryFilters
                                    },
                                    this.displayProductsBasedOnCategories(
                                      tag.id,
                                      tag.name,
                                      1
                                    )
                                    )
                                  }}>
                                  {tag.name}
                                </Tag>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div 
                      style={{
                        display: "flex",
                        alignItems: "center"
                      }}
                      className="col-md-2 col-sm-2 col-xs-2">
                      {/*    <button
                        className="otherButtons"
                        onClick={this.pClick.bind(this)}
                      >
                        Clear All
                      </button> */}
                      <p className="pClearAll" onClick={this.pClick.bind(this)}>
                        Clear All
                      </p>
                    </div>
                  </div>
                  </div>
                )}

                <div className="container NC-listing-div">
                  {/* <div className="row"> */}
                  {/* {!comingsoon ? ( */}
                  {/* <div className="col-sm-12 col-12 col-md-12 pad0 "> */}
                  {!comingsoon ? (
                    <div className="col-sm-12 col-12 col-md-12 pad0 ">
                      {this.state.catFilter != undefined && (
                        <div className="row">
                          {this.state.catFilter.map(({ node, index }) => (

                            <div
                              className="col-sm-6 col-12 col-md-3 prodListing"
                              key={node.id}
                            >
                              {/* <Link
                    to={`/nativeproduct/${node.slug}/`}
                    href={`/nativeproduct/${node.slug}/`}
                  > */}

                              {/* <div className="product"> */}
                              <div className="productDiv" key={node.id}>
                                {/* <div className="prodDiscount">

                                <p className="prodDiscountPercentage">-10%</p>
                            </div> */}
                                {node.cartItem == true ? (
                                  <p className="pIncart">In cart</p>
                                ) : (
                                    ""
                                  )}

                                <Link
                                  to={`/nativeproduct/${node.slug}/`}
                                  href={`/nativeproduct/${node.slug}/`}
                                >
                                  <div className="productlistimg">
                                    <img
                                      src={
                                        node.acf.listingCardImage == null
                                          ? "http://dashboard.nativecircle.in/wp-content/uploads/2019/08/hs250.jpg"
                                          : node.acf.listingCardImage.source_url
                                      }
                                      className="prodImg img-fluid"
                                      alt="Responsive image"
                                    />
                                  </div>

                                  <div className="prodPad">
                                    {/* <div className="rating">
                                <div className="star">
                                  <input name="stars" id="e5" type="radio" />
                                  <label htmlFor="e5">☆</label>
                                </div>
                                <div className="star">
                                  <input name="stars" id="e4" type="radio" />
                                  <label htmlFor="e4">☆</label>
                                </div>
                                <div className="star">
                                  <input name="stars" id="e3" type="radio" />
                                  <label htmlFor="e3">☆</label>
                                </div>
                                <div className="star">
                                  <input name="stars" id="e2" type="radio" />
                                  <label htmlFor="e2">☆</label>
                                </div>
                                <div className="star">
                                  <input name="stars" id="e1" type="radio" />
                                  <label htmlFor="e1">☆</label>
                                </div>
                              </div> */}

                                    {/* <Link to={'post/' + node.slug}>
                            <h3>{node.title}</h3>
                        </Link> */}

                                    {/* <div className="productNme"> */}
                                    <div className="fprodName">
                                      <p style={{ marginBottom: "0px" }}>
                                        {this.removeHtmlEntities(node.title)}
                                      </p>
                                      {/* {console.log("product_details",node.acf.product_details.length)} */}
                                      {node.acf.product_details.length == 1 ?
                                        node.acf.product_details.map((product) => <p>{product.quantity}</p>)
                                        : <p></p>
                                      }
                                    </div>

                                    {/* </div> */}
                                    {/* <div className="productRupees"> */}
                                    {/* <p className="prodRupeesScratch"><span>&#8377;</span>{node.acf.discount}</p> */}
                                    <p className="prodRupeesNoScratch">
                                      <span>&#8377;</span>
                                      {node.acf.price}
                                    </p>
                                  </div>
                                </Link>
                                {/* </div> */}
                              </div>

                              <div className="productOnHover">
                                {node.cartItem == true ? (
                                  <p className="pIncart">In cart</p>
                                ) : (
                                    ""
                                  )}

                                <Link
                                  to={`/nativeproduct/${node.slug}/`}
                                  href={`/nativeproduct/${node.slug}/`}
                                >
                                  <div className="onHovProdPad">
                                    {/* <div className="rating">
                                  <div className="star">
                                    <input name="stars" id="e5" type="radio" />
                                    <label htmlFor="e5">☆</label>
                                  </div>
                                  <div className="star">
                                    <input name="stars" id="e4" type="radio" />
                                    <label htmlFor="e4">☆</label>
                                  </div>
                                  <div className="star">
                                    <input name="stars" id="e3" type="radio" />
                                    <label htmlFor="e3">☆</label>
                                  </div>
                                  <div className="star">
                                    <input name="stars" id="e2" type="radio" />
                                    <label htmlFor="e2">☆</label>
                                  </div>
                                  <div className="star">
                                    <input name="stars" id="e1" type="radio" />
                                    <label htmlFor="e1">☆</label>
                                  </div>
                                </div> */}
                                    <div className="productlistimg">
                                      <img
                                        src={
                                          node.acf.listingCardImage == null
                                            ? "http://dashboard.nativecircle.in/wp-content/uploads/2019/08/hs250.jpg"
                                            : node.acf.listingCardImage.source_url
                                        }
                                        className="prodImg img-fluid"
                                        alt="Responsive image"
                                      />
                                    </div>

                                    <div className="onHovProdName">
                                      <p>{this.removeHtmlEntities(node.title)}</p>
                                    </div>

                                    <p className="onHovProdPrice">
                                      <span>&#8377;</span>
                                      {node.acf.price}
                                    </p>

                                    {/*  <div className="fprodName">
                                <p>
                                  {this.state.tagDisp == true
                                    ? console.log("true")
                                    : console.log("false")}
                                </p>
                              </div> */}
                                  </div>
                                </Link>

                                {node.cartItem == true ? (
                                  <button
                                    className="enquireBtn"
                                    onClick={this.removeFromCart.bind(
                                      this,
                                      node.id
                                    )}
                                  >
                                    Remove from cart&nbsp;&nbsp;
                                    <span className="spanClose">×</span>
                                  </button>
                                ) : (
                                    <button
                                      className="enquireBtn"
                                      onClick={this.addToCart.bind(this, node.id)}
                                    >
                                      <p> Add to Cart </p>
                                      <img
                                        className="CheckoutIcon"
                                        src="https://res.cloudinary.com/djoztpm50/image/upload/v1551866451/Native%20Circle/path_1.svg"
                                      />
                                    </button>
                                  )}
                              </div>
                              {/* </div> */}
                              {/* </Link> */}
                            </div>
                          ))}
                        </div>
                      )}

                      <div className="paginate">
                        <Pagination
                          activePage={this.state.activePage}
                          itemsCountPerPage={12}
                          totalItemsCount={itemLength}
                          pageRangeDisplayed={5}
                          disabledClass="disabled"
                          activeLinkClass="page-link"
                          itemClass="page-item"
                          linkClass="page-link"
                          onChange={this.handlePageChange.bind(this)}
                        />
                      </div>
                    </div>
                  ) : (
                      <h4 className="noProducts" style={{ marginTop: "2em" }}>
                        No products are available currently for this category. Stay Tuned, We
                        will update this section shortly
                      </h4>
                    )}{" "}
                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>
          {/*     <div className="NC-paddingTop-50">
          <Footer />
        </div> */}
        </Spin>
      </div>
    );
  }
}

// gsh
export default Products;

export const pageQuery = graphql`
  query nativeproductsQuery {
    allWordpressWpProductCategories(filter: {
      wordpress_parent: {
        nin: [48, 47]
      }
      wordpress_id: {
        nin: [48, 47]
      }
    }) {
      edges {
        node {
          id
          wordpress_id
          name
          wordpress_parent
          parent_element {
            id
            name
          }
        }
      }
    }
    allWordpressWpProductCategoryV2 {
      totalCount
      edges {
        node {
          name
          id
          wordpress_id
          wordpress_parent
          slug
        }
      }
    }
    allWordpressWpTaxonomies {
      edges {
        node {
          id
          wordpress_id
          name
        }
      }
    }
    allWordpressWpNativeproduct {
      edges {
        node {
          id
          title
          slug
          date(formatString: "MMMM DD, YYYY")
        }
      }
    }
  }
`;
